.breadcrumbs-wrapper {
  left: 8px; right: 8px;
  bottom: 3px;
  position: fixed;
  padding: 0 15px 8px 15px;
  z-index: 999;
  box-shadow: none !important;
}

.breadcrumbs-version {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 9999;
  padding: 0 5px 8px 15px;
  font-size: 10px;
}