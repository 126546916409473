.filter-top-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 15px 0;
}

.filter-top-row div {
  margin-right: 10px;
}
