.frame-wrapper {
  margin: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  height: stretch;
}

.frame-wrapper .erase-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
}

.frame-wrapper .hidden {
  display: none;
}
.frame-wrapper .notifier-wrapper {
  position: fixed;
  left: 50%;
  width: 500px;
  margin-left: -250px;
  top: 15px;
  z-index: 999999;
  font-size: 17px;
  text-align: center;
}

i.db-access {
  color: #ccc;
  font-size: 17px;
}

.project-nav {
  margin-bottom: 3px;
  border-radius: 6px;
}

.swatches {
  margin-right: 15px;
}
.swatches-list {
  margin-right: 35px;
}

.swatches-list-item {
  z-index: 999999;
  font-size: 10px;
}

div.nav-hidden {
  visibility: hidden;
}

.backend i {
  color: #e0e0e0;
}

.backend.active i {
  color: #1d72aa;
}

.db i {
  color: #e0e0e0;
}

.db.active i {
  color: #00947e;
}
