body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hidden {
  visibility: hidden;
}

.main-box {
  right: 17px;
  left: 17px;
  top: 17px;
  bottom: 17px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-bar {
  margin: 8px 16px;
  min-height: 38px;
  width: 76%;
}

.drop-zone {
  height: 350px;
  width: 350px;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  border: 5px dotted #ccc;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.drop-zone-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.zipper {
  font-size: 98px;
  opacity: 0.5;
}

ul.file-tree {
  margin: 0 15px;
}

.entry {
  padding: 3px 5px;
  border-radius: 8px;
  cursor: pointer;
}
.entry:hover {
  background-color: #ebfffc;
  color: #00947e;
}

.entry.full {
  font-weight: bold;
  background-color: #ebfffc;
}

.modal.is-active {
  z-index: 999;
}

.dirpic {
  padding: 3px 15px;
  margin: 0 8px 0 0;
  border-radius: 8px;
  border: 1px solid #333;
}

.colpic {
  padding: 3px 15px;
  margin: 0 8px 0 0;
  border-radius: 8px;
  border: 1px solid #333;
}

.filepic.pdf {
  background-color: #f14668;
  color: #fff;
}

.filepic.csv {
  background-color: #ffdd57;
  color: #777;
}

.filepic.xls {
  background-color: #00d1b2;
  color: #333;
}

.filepic.zip {
  background-color: #3298dc;
  color: #fff;
}

.audit-file {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  flex-direction: row;
}

.audit-file .button-wrapper {
  flex: 3;
  visibility: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.audit-file:hover div.button-wrapper {
  visibility: visible;
}

.audit-file .button-wrapper button {
  margin: 0 15px;
}

.audit-file:hover {
  background-color: #ebfffc;
  color: #00947e;
}

.small-table td {
  font-size: 10px;
}

.menu-button {
  margin: 5px;
}

.main-menu {
  min-width: 350px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.menu-items {
  margin: 35px 17px;
  width: 95%;
}

.wide-screen {
  width: 90% !important;
}

.medium-screen {
  width: 50% !important;
}

.wide-wide {
  width: 90%;
  height: 90%;
}

.i-frame {
  width: 100%;
  height: 100%;
  margin: 15px;
}

.file-box-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  margin: 0px;
  padding: 15px;
}

.file-box-files {
  background-color: white;
}
.file-box i {
  margin: 0 15px 0 5px;
}

.file-box.open .file-box-top {
  background-color: #ebfffc;
}

.file-box.open i.fa-box-open {
  color: #00d1b2;
  display: block;
}
.file-box.open i.fa-box {
  display: none;
}

.file-box i.fa-box {
  color: #777;
  display: block;
}
.file-box i.fa-box-open {
  display: none;
}

.file-box .box-button {
  opacity: 0;
}

.file-box.open .box-button {
  opacity: 1;
}

.file-box span {
  flex: 3;
  font-weight: bold;
}

.project-item {
  cursor: pointer;
}

.hands-on-wrapper {
  overflow: hidden;
  width: 100%;
  min-height: 500px;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation__ {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

div.modal {
  z-index: 99999 !important;
}
