.loading-project-wrapper {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.norming-wrapper {
  min-height: 90px;
}

.frame-note {
  margin: 15px 0 35px 0;
}

.tags.alternatives {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.tags.alternatives .tag {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.small-print {
  font-size: 10px;
}

.table-compact td {
  font-size: 13px;
}

.is-clickable {
  cursor: pointer;
}

.pool-tags {
  cursor: pointer;
}

.top-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.top-row div,
.top-row progress {
  margin: 0 15px;
}

.review-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.review-progress {
  flex: 3;
  margin: 0 35px;
}

.footer-buttons {
  width: 100%;
  padding: 0;
  margin: 0;
}

.footer-buttons button {
  margin: 0 15px;
}

.status-wrapper {
  margin: 0 15px;
  display: inline;
}

.is-clickable {
  cursor: pointer;
}

.progressWrapper {
  min-width: 100px;
}

.sort-sign {
  color: #999;
}

.clickable-header {
  cursor: pointer;
  border-radius: 5px;
}
.clickable-header__:hover {
  color: #777;
}

.table-wrapper {
  height: 70vh;
  overflow: auto;
  margin: 8px 0;
}

.table-wrapper-shorter {
  height: 60vh;
  overflow: auto;
  padding: 8px;
}

.input-wrapper {
  min-width: 250px;
  cursor: pointer;
  border: 1px solid #fff;
}

.input-wrapper:hover {
  border: 1px dotted #ccc;
}

.not-above {
  background-color: #f14668 !important;
  color: #fff !important;
}

td.vmid {
  vertical-align: middle !important;
}

thead th {
  position: sticky !important;
  top: 0;
  background-color: #fafafa;
  z-index: 9999;
}

tfoot th {
  position: sticky !important;
  bottom: 0;
  background-color: #ccc;
}

.panel-block.spread {
  justify-content: space-between;
}

.search-result-id {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.height-75 {
  height: 75vh;
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.bottom-zero {
  margin-bottom: 0 !important;
}
