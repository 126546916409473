.signin-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0; 
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0; 
  background-image: url(background.jpg) ;
  background-position: center center;
  background-repeat:  no-repeat;
  background-attachment: fixed;
  background-size:  cover;
  background-color: #999;
}

.signin-container .wrapper {
  background-color: rgba(0,0,0,0.7);
}

.signin-container .signin-wrapper {
  background-image: linear-gradient(-45deg, #618BA2 0%, #91B8CA 100%);
}

.signin-container .bird-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0 15px 0;
}

.signin-container .button-row {
  margin: 35px 0 0 0;
}