.table.is-small-table td,
.table.is-small-table tr {
  font-size: 10px;
}

button.rebuild {
  margin: 0 0 15px 0;
}

.updated-box {
  margin: 0 0 15px 0;
}
