.frame-wrapper {
  margin: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  height: stretch;
}

.frame-wrapper .notifier-wrapper {
  position: fixed;
  left: 50%; width: 500px; margin-left: -250px;
  top: 15px; z-index: 999999;
  font-size: 17px; text-align: center;
  border-radius: 15px;
}

.project-nav {
  margin-bottom: 3px;
  border-radius: 6px;
}