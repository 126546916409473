.artist-wrapper {
  margin: 5px 0 0 0;
}

.progress-wrapper {
  display: flex;
  flex-direction: columns;
  justify-content: center;
  align-items: center;
}

.progress-wrapper span {
  margin: 0 5px;
}

.is-hoverable:hover {
  background-color: #fafafa;
}

.click-box:hover {
  background-color: #fafafa;
}

.click-box {
  display: flex !important;
  flex-direction: column !important;
  gap: 0.5rem !important;
  cursor: pointer !important;
}
