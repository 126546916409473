.load-projects {
  display: flex;
  justify-content: center;
}

.icon.spacer-active {
  margin: 0 15px;
  color: green;
}

.icon.spacer-inactive {
  margin: 0 15px;
  color: #ddd;
}

.is-client {
  cursor: pointer;
}
